export default function Configs() {
    const searchItems = [
        {
            label: "检查项目",
            span: 12,
            model: "name",
            clearable: true,
            placeholder: '请输入'
        },
        {
            label: "缺损类型",
            span: 12,
            model: "defectTypeName",
            clearable: true,
            placeholder: '请输入'
        },
    ];
    const tableColumns = [
        {
            label: "检查项目",
            prop: "name",
            align: "center",
            minWidth: 140,
            show: true
        },
        {
            label: "缺损类型",
            prop: "defectTypeName",
            align: "center",
            show: true
        },
        {
            label: "操作",
            name: 'custom',
            align: "center",
            width: '100px',
            show: true
        },
    ];
    return {
        searchItems,
        tableColumns
    };
}
