<template>
  <div class="report-main">
    <div class="nav-list">
      <div
        :class="navActive === index ? 'nav-con' : 'nav-con nav-con-active'"
        v-for="(item, index) in navList"
        :key="index"
        @click="navClick(index)"
      >
        <span>{{ item.name }}</span>
      </div>
    </div>
    <div class="tit-search flex space-between">
      <div class="mb-2">
        <el-button class="btn-primary" @click="goto('')">新增</el-button>
      </div>
      <c-search
        v-model="pageParams"
        :searchItems="searchItems"
        @search="handleSearch"
        @reset="handleReset"
      ></c-search>
    </div>
    <div class="div-scroll table-wrapper">
      <c-table
        :index="false"
        :data="pageData"
        :tableColumns="tableColumns"
        :stripe="true"
        :pagination="pageParams"
        @change="pageGet"
        v-loading="pageLoading"
      >
        <template v-slot:custom="{ row }">
          <span class="opt-detail" style="margin-right: 10px" @click="goto(row)"
            >编辑</span
          >
          <span
            class="opt-detail"
            style="margin-right: 10px"
            @click="handlePageDelete(row)"
            >删除</span
          >
        </template>
      </c-table>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogVisible"
      :destroy-on-close="true"
    >
      <div class="div-scroll" style="max-height: 500px; padding: 0 2%">
        <div v-loading="handleLoading">
            <el-form ref="form" :model="handleData" label-width="120px">
          <el-form-item
            label="检查项目"
            prop="name"
            :rules="{
              required: true,
              message: '请输入',
              trigger: 'blur',
            }"
          >
            <el-input v-model="handleData.name" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="缺损类型" required>
            <el-table :data="handleData.defectTypeList" border>
              <el-table-column label="缺损类型" align="center">
                <template slot-scope="scope">
                  <el-form-item
                    label-width="0"
                    :prop="'defectTypeList.' + scope.$index + '.name'"
                    :rules="{
                      required: true,
                      message: '请输入',
                      trigger: 'blur',
                    }"
                  >
                    <el-input
                      v-model="scope.row.name"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column align="center" width="100px">
                <template slot="header" slot-scope="scope">
                  <el-button class="btn-primary" type="mini" @click="handleAdd"
                    >新增</el-button
                  >
                </template>
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="danger"
                    @click="handleDelete(scope.$index, scope.row)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetFields">取 消</el-button>
        <el-button class="btn-primary" @click="submit('form')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MHandle from "@/mixins/MHandle.js";
import MPage from "@/mixins/MPage.js";
import Configs from "./config/index";
let mPage = MPage({
  pagePath: "",
  pageDelPath: "/inspection/item/delete",
  pageDelsPath: "",
  pageParams: {
    name: "",
    defectTypeName: "",
    type: "",
  },
  pageExportPath: "",
  pageExportParams: {},
});
let mHandle = MHandle({
  handleGetPath: "/inspection/item",
  handleInsertPath: "/inspection/item/save",
  handleUpdatePath: "/inspection/item/update",
  handleDownLoadPath: "",
  handleData: {},
});
export default {
  mixins: [mPage, mHandle],
  data() {
    let { searchItems, tableColumns } = Configs(this);
    return {
      searchItems,
      tableColumns,
      navList: [
        { value: 0, name: "日常巡检配置" },
        { value: 1, name: "经常巡检配置" },
      ],
      navActive: 0,
      dialogVisible: false,
      title: "",
      defaultHandleData: {
        name: "",
        defectTypeList: [{ name: "" }],
      },
    };
  },
  created() {
    this.handleData = Object.assign({}, this.defaultHandleData);
  },
  mounted() {
    this.navClick(this.navActive);
  },
  methods: {
    navClick(i) {
      this.navActive = i;
      this.pageParams.type = Number(i) + 1;
      this.pagePath = "/inspection/item/listPage";
      this.pageGet(true);
    },
    handleSearch() {
      this.pageGet(true);
    },
    handleReset() {
      this.idList = [];
      this.pageParams = {
        name: "",
        defectTypeName: "",
        type: Number(this.navActive) + 1,
      };
      this.pageGet(true);
    },
    pageGetAfterFn(data) {
      data.list = data.records;
      return data;
    },
    goto(data) {
      this.handleData = Object.assign({}, this.defaultHandleData);
      this.handleData.defectTypeList = [{ name: "" }];
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.form && this.$refs.form.clearValidate();
      });
      if (data) {
        this.title = "编辑";
        this.handleGet(data.id);
      } else {
        this.title = "新增";
      }
    },
    handleGetAfterFn(data) {
      let handleData = Object.assign({}, data);
      return handleData;
    },
    handleBeforeFn(data) {
      let params = Object.assign({}, data);
      params.type = Number(this.navActive) + 1;
      return params;
    },
    handleAdd() {
      this.handleData.defectTypeList.push({ name: "" });
    },
    handleDelete(index) {
      this.handleData.defectTypeList.splice(index, 1);
    },
    // 删除列表项目
    handlePageDelete(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        let params = { id: row.id };
        this.$http
          .post("/inspection/item/delete", null, { params })
          .then((res) => {
            if (res.success) {
              this.$message.success(res.data);
              this.pageGet(true);
            } else {
              this.$message.erroe(res.msg);
            }
          })
          .catch((err) => {
            this.$message.erroe("操作失败！" + err);
          });
      });
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.handleData.id) {
            this.handleUpdate();
          } else {
            this.handleInsert();
          }
        }
      });
    },
    handleAfterFn() {
      this.dialogVisible = false;
      this.pageGet(true);
    },
    resetFields() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.report-main {
  width: 100%;
  height: 100%;
  background-color: #fff;
  .nav-list {
    width: 100%;
    height: 40px;
    line-height: 40px;
    display: flex;
  }
  .nav-con {
    width: 160px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-left: 1px solid #1a67d0;
    border-right: 1px solid #1a67d0;
    border-top: 1px solid #1a67d0;
    color: #1a67d0;
    font-weight: bold;
  }
  .nav-con:hover {
    cursor: pointer;
  }
  .nav-con i {
    color: #1a67d0;
    margin-right: 5px;
    font-weight: bold;
    font-size: 20px;
  }
  .nav-con-active {
    color: #fff;
    background: #1a67d0;
    border-bottom: 1px solid #1a67d0;
  }
  .nav-con-active i {
    color: #fff;
  }

  .tit-search {
    padding: 20px 20px 0;
  }
  .table-wrapper {
    padding: 0 20px 20px;
    background: #fff;
    height: calc(100% - 119px);
  }
}
</style>
